import merge from 'lodash/merge';
import {useSearchParams} from '@remix-run/react';
import type {LinksFunction} from '@remix-run/server-runtime';

import type {Handle} from '@/types';
import Conversion from '@/components/base/sections/Conversion/Conversion';
import Section from '@/components/base/layouts/Section/Section';
import SideBySideList from '@/components/base/sections/SideBySideList/SideBySideList';
import Faq from '@/components/base/modules/Faq/Faq';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import PageLayout from '@/components/shared/Page/PageLayout';
import {ColorSchemeEnum} from '@/enums';
import {useTranslations} from '@/hooks/useTranslations';
import ChannelsHeader from '@/pages/shopify.com/($locale)/channels/components/ChannelsHeader';
import type {ImageProps} from '@/components/base/elements/Image/Image';
import CardGrid from '@/components/base/layouts/CardGrid/CardGrid';
import Card, {type CardProps} from '@/components/base/modules/Card/Card';
import HeroFloatingMedia from '@/components/pages/shared/Hero/HeroFloatingMedia';
import InlineVideoIOWrapper from '@/components/pages/shared/InlineVideo/InlineVideoIOWrapper';
import InlineVideo from '@/components/pages/shared/InlineVideo/InlineVideo';
import Typography from '@/components/base/elements/Typography/Typography';
import type {VideoComponentWithDifferentQualities} from '@/components/pages/shared/InlineVideo/types';

import fbStylesheet from './styles/facebook-instagram.css?url';

export {loader} from '@/utils/server/loader/loader';

export const links: LinksFunction = () => {
  return [{rel: 'stylesheet', href: fbStylesheet}];
};

export const handle: Handle = {
  mainClass: 'with-text-wrap-balance',
  signupTypes: ['marketplace_and_social_media_guidance__social_media'],
};

const faqUSOnly = ['en', 'en-SG', 'en-PH'];
const faqOther = ['ja', 'zh-CN'];
const countriesWithoutShopPay = [
  'ko',
  'pt-BR',
  'vi',
  'zh-CN',
  'zh-TW',
  'en-ZA',
  'en-ID',
  'en-IN',
  'en-MY',
  'en-NG',
  'en-NO',
  'en-PH',
  'es-CO',
  'es-MX',
];
const localesWithUtm = ['en', 'en-CA', 'en-GB', 'en-AU'];

function checkUtmParam({
  altHeroUtmSourceValues,
  searchParams,
}: {
  altHeroUtmSourceValues: Array<String> | undefined;
  searchParams: URLSearchParams;
}) {
  // If there's no valid param values for the alternate CTA, return false right away
  if (!altHeroUtmSourceValues || altHeroUtmSourceValues.length < 1)
    return false;

  const utmSourceParamValue = searchParams.get('utm_source');
  const pageParamValue = searchParams.get('page');

  const isValidUtmSourceParamValue =
    utmSourceParamValue !== null &&
    altHeroUtmSourceValues.includes(utmSourceParamValue);

  const isValidPageParamValue =
    pageParamValue !== null && altHeroUtmSourceValues.includes(pageParamValue);

  return isValidUtmSourceParamValue || isValidPageParamValue;
}

export default function FacebookInstagram() {
  const [searchParams] = useSearchParams();
  const {t, images, site, localeData} = useTranslations();
  const locale = site.locale;

  // Hero
  const heroMedia: {images?: [ImageProps]; mediaComponent?: React.ReactNode} =
    {};
  const heroSettings = images.hero;

  if (heroSettings.video && heroSettings.video[site.locale]) {
    const video: VideoComponentWithDifferentQualities =
      heroSettings.video[site.locale];

    heroMedia.mediaComponent = (
      <div className="aspect-square">
        <InlineVideoIOWrapper>
          <InlineVideo video={video} />
        </InlineVideoIOWrapper>
      </div>
    );
  } else {
    heroMedia.images = [
      {
        alt: t('hero.image.alt'),
        src: localeData(heroSettings.image),
      },
    ];
  }

  // check if need to use alternative cta text for UTM
  const altHeroUtmSourceValues = localesWithUtm.includes(locale)
    ? ['meta']
    : undefined;

  const isAlternate = checkUtmParam({altHeroUtmSourceValues, searchParams});

  // update alternative UTM CTA,
  // and remove unessessary data
  const heroContent = t('hero');
  if (isAlternate) {
    heroContent.subheadHtml = t('hero.subheadHtmlAlt');
    heroContent.ctas = heroContent.ctasAlt;
    heroSettings.ctas = heroSettings.ctasAlt;
  }
  delete heroContent.subheadHtmlAlt;
  delete heroContent.ctasAlt;
  delete heroSettings.ctasAlt;

  const heroData = merge(heroContent, heroSettings);

  // SideBySideList
  const sideBySideListImage: ImageProps = {
    alt: t('sideBySideList.image.alt'),
    src: localeData(images.sideBySideList.image),
  };

  const sideBySideListImage2: ImageProps = {
    alt: t('sideBySideList2.image.alt'),
    src: localeData(images.sideBySideList2.image),
  };

  const sideBySideList2Data = merge(
    t('sideBySideList2'),
    images.sideBySideList2,
  );

  // Remove shop pay blurb if not available in the country
  sideBySideList2Data.sideBySideItems =
    sideBySideList2Data.sideBySideItems.filter((item: any) => {
      if (countriesWithoutShopPay.includes(site.locale) && item.hasShopPay) {
        return false;
      }
      return true;
    });

  return (
    <PageLayout
      // header={ChannelsHeader('facebookInstagram')}
      header={(props) => ChannelsHeader(props)}
      footer={<Footer colorScheme={ColorSchemeEnum.Dark} />}
    >
      <HeroFloatingMedia
        {...heroData}
        {...heroMedia}
        colorScheme={ColorSchemeEnum.Agave}
        className="bg-white facebook-instagram-gradient [&_.media-container]:md:py-0"
      />

      <Section
        componentName="outcomes"
        topSpacing="2xl"
        bottomSpacing="2xl"
        className="bg-black"
      >
        <div className="container">
          <CardGrid
            numberOfColumns={t('cardGrid.cards').length}
            extraVerticalSpacing
          >
            {t('cardGrid.cards').map((card: CardProps, idx: number) => {
              const cardContent = merge(card, images.cardGrid.cards[idx]);
              return (
                <Card key={idx} {...cardContent} size="small" mode="dark" />
              );
            })}
          </CardGrid>
        </div>
      </Section>

      <div className="meta-simple-gradient pb-0">
        <SideBySideList
          {...merge(t('sideBySideList'), images.sideBySideList)}
          sectionTopSpacing="2xl"
          verticalAlign="center"
          className="bg-transparent pb-0 gap-y-8"
          image={sideBySideListImage}
        />
        <SideBySideList
          {...sideBySideList2Data}
          sectionBottomSpacing="2xl"
          verticalAlign="center"
          visualPosition="right"
          className="bg-transparent pt-16 gap-y-8"
          image={sideBySideListImage2}
        />

        <div className="container flex flex-col gap-y-4">
          <Typography size="t7" as="p" className="text-shade-70">
            {t('disclaimer.headingHtml')}
          </Typography>
          <Typography size="p" as="p" className="text-shade-60">
            {t('disclaimer.subheadHtml')}
          </Typography>
        </div>
      </div>

      <Section topSpacing="2xl">
        <SectionHeader center size="t2" headingAs="h2" {...t('faq')} />
        <Faq
          accordionItems={t('faq.questions').map((item: any, index: number) => {
            // Content might change based on locale
            if (faqUSOnly.includes(site.locale) && item.contentUS)
              item.content = item.contentUS;

            if (faqOther.includes(site.locale) && item.contentOther)
              item.content = item.contentOther;

            return {
              ...item,
              id: `faq_${index}`,
            };
          })}
        />
      </Section>

      <Section
        componentName="conversion"
        className="facebook-instagram-conversion-gradient bg-agave-10"
        topSpacing="2xl"
        bottomSpacing="2xl"
      >
        <Conversion
          {...merge(t('conversion'), images.conversion)}
          mode="light"
        />
      </Section>
    </PageLayout>
  );
}
